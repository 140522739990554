import React from 'react';

import SEO from '../components/SEO';
import TextPage from '../components/TextPage';

const FaqPage = props => (
  <TextPage location={props.location}>
    <SEO title="常見問題" />

    <h1>常見問題</h1>

    <h4>儲方的服務範圍？</h4>
    <p>
      我們的存倉服務範圍目前覆蓋香港島及九龍。
      然而，我們正在迅速擴張，並將會為新界地區提供服務。請登記賬戶，當我們的服務擴展至您的地區，我們會在第一時間通知您！
    </p>

    <h4>儲方的營業時間？</h4>

    <p>
      我們的客戶服務時間為星期一至日的早上10時至晚上7時，公眾假日休息。如您在非營業時間有任何疑問，歡迎於任時間致電21113113或電郵至info@roomyr.com或Whatsapp
      63572288，我們將盡快回覆
    </p>
    <h4>倉庫有什麼安全監控系統？</h4>

    <p>設有全天候攝錄監控。倉庫有完善溫度與濕度控制系統。我們的倉庫跟據最新消防指引設計，並且運作正常</p>
    <h4>我如何繳交費用？</h4>

    <p>我們接受主要銀行轉帳，現金或支票付款</p>
    <h4>最少儲存多長時間？</h4>

    <p>最少儲存期為1個月</p>
    <p>是否有任何不能儲存物品？</p>

    <p>
      我們堅決拒收任何違禁品，如危險品、易燃物品、非法物品及易腐物品等。我們客户服務有權在收取物品過程中拒收任何類別的違禁品。易燃物品包括但不只限於以下物件：
    </p>
    <ul>
      <li>易燃物品</li>
      <li>膠粘劑</li>
      <li>防凍劑</li>
      <li>含有70％或更多的酒精通過音量等飲料</li>
      <li>含有酒精的沐浴露</li>
      <li>制動，傳動和擋風玻璃清洗液</li>
      <li>露營煤氣、煮食用火槍及其他有害氣體</li>
      <li>地毯清潔劑</li>
      <li>含有松油的清潔劑</li>
      <li>食用油</li>
      <li>乾洗劑</li>
      <li>引擎添加劑</li>
      <li>易燃氣體（如丁烷氣體）</li>
      <li>易燃液體（如汽油，火機油及柴油等）</li>
      <li>易燃固體（如非安全性火柴、一次性燒烤用具及煤）</li>
      <li>麵粉</li>
      <li>頭髮造型泡沫</li>
      <li>定型噴霧和其他含有酒精成分的理髮產品</li>
      <li>含有酒精的洗手液</li>
      <li>打火機油</li>
      <li>打火機</li>
      <li>塗改液</li>
      <li>火柴、木炭、磷及容易燃點的物件</li>
      <li>油性顏料</li>
      <li>油漆稀釋劑</li>
      <li>油性顏料、天拿水（稀釋液）及液體黏合劑</li>
      <li>香水</li>
      <li>奶粉</li>
      <li>農藥</li>
      <li>噴霧（如定形噴霧，防曬噴霧）</li>
      <li>噴霧式潤滑劑</li>
      <li> 油漆</li>
    </ul>
  </TextPage>
);

export default FaqPage;
